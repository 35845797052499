const AWS_CF_BASE_URL = "https://images.onthelook.co.kr";
const CLOUDFLARE_BASE_URL = "https://imagedelivery.net/WN97BdzLVKaS9hBULE5dag";

export const IC_CREATOR_MARK = AWS_CF_BASE_URL + "/app/post/ic-badge-creator.png";
export const IC_ETC = AWS_CF_BASE_URL + "/app/post/ic-etc.png";
export const IC_POST_TAG = AWS_CF_BASE_URL + "/app/post/ic-posttag.png";
export const IC_POST_ZOOM = AWS_CF_BASE_URL + "/app/post/ic-postzoom.png";
export const IC_POST_LIKE_NOR = AWS_CF_BASE_URL + "/app/post/ic_post_like_nor.png";
export const IC_POST_LIKE_SEL = AWS_CF_BASE_URL + "/app/post/ic_post_like_sel.png";
export const IC_POST_PAGING_NOR = AWS_CF_BASE_URL + "/app/post/paging_nor.png";
export const IC_POST_PAGING_SEL = AWS_CF_BASE_URL + "/app/post/paging_sel.png";
export const IC_POST_STICKER_HEART = AWS_CF_BASE_URL + "/app/post/sticker-heart.png";
export const IC_BOOKMARK = AWS_CF_BASE_URL + "/app/post/ic_bookmark.png";
export const IC_BOOKMARK_OFF = AWS_CF_BASE_URL + "/app/post/ic_bookmark_grey_off.png";
export const IC_BOOKMARK_ON = AWS_CF_BASE_URL + "/app/post/ic_bookmark_on.png";
export const IC_BOOKMARK_SEL = AWS_CF_BASE_URL + "/app/post/ic_bookmark_sel.png";
export const IC_BOOKMARK_WHITE =AWS_CF_BASE_URL + "/app/post/ic-bookmark-white.png";
export const IC_COMMENT = AWS_CF_BASE_URL + "/app/post/ic_comment.png";
export const IC_DEFAULT_AVATAR = AWS_CF_BASE_URL + "/u/ic-avatar-1.png";
export const IC_PLUS = AWS_CF_BASE_URL + "/app/ic-16-add.png";
export const IC_LOGO = CLOUDFLARE_BASE_URL + "/da953394-7dc2-4084-87d8-57bf71d18900/420";
export const IC_MY_GUIDE = CLOUDFLARE_BASE_URL + "/ab3e77a7-d8db-4a89-e2b3-311942254f00/public";
export const IC_MY_KAKAO = CLOUDFLARE_BASE_URL + "/ea279a36-f8d9-4ca6-4c15-3b40b3122d00/public";
export const IC_MY_PLUS = CLOUDFLARE_BASE_URL + "/98332217-1cd4-4557-1812-92df7940bd00/public";
export const IC_PRODUCT_ETC = AWS_CF_BASE_URL + "/pr/ic_product_etc.jpg";
export const IC_GREY_CLOSE = CLOUDFLARE_BASE_URL + "/87868451-966f-4451-3917-c3718862b100/256";
export const IC_QUICK_FILTER_CLOSE = CLOUDFLARE_BASE_URL + "/6472788b-3058-457c-93fb-8b6ce000b900/128";
export const IC_EXTEND_DOWN = CLOUDFLARE_BASE_URL + "/b75bd8c4-340a-414d-c64e-22c5e3e08000/128";